import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'input-card',
    templateUrl: './input-card.component.html',
    styleUrls: ['./input-card.component.css'],
    standalone: true,
    imports: [NgIf, NgTemplateOutlet]
})
export class InputCardComponent { 

    @Input() id: number;
    @Input() imgUrl: string;
    @Input() name: string;
    @Input() elementStyle: string;
    @Input() text: string;

    @ContentChild('cardContent') contentTemplateRef: TemplateRef<any> = null;
    
}
