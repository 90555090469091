<div 
    class="input-card-component">
    <div *ngIf="imgUrl" class="img-container">
        <img [src]="imgUrl" alt="image">
    </div>
    <div *ngIf="elementStyle" [class]="elementStyle">
        <div *ngIf="innerText" class="element-text">{{ innerText }}</div>
    </div>
    <div class="input-name">{{ name }}</div>

    <!-- Client-defined content template -->
    <ng-container
        [ngTemplateOutlet]="contentTemplateRef">
    </ng-container>

</div>